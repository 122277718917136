import React, { useState} from 'react';
import { InputProps } from '../Types/types';

function Input({ onInputEntered }: InputProps) {

    const [input, setInput] = useState('');

    function handleInputEntered(): void {
        onInputEntered(input);
        setInput('');
    }

    return(
        <div className="chat-input">
            <input
                type="text"
                placeholder={'Send Message'}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleInputEntered()}/>
        
            <button onClick={handleInputEntered}>Send</button>
      </div>
    )
}

export default Input;