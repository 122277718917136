export interface IndustryOption {
    value: string;
    name: string;
}

export const industryOptions: IndustryOption[] = [
    {value: '', name: 'Select One'},
    {value: 'aerospace_and_defense', name: 'Aerospace & Defense'},
    {value: 'automotive', name: 'Automotive'},
    {value: 'banks', name: 'Banks'},
    {value: 'charities_foundations_and_non-profits', name: 'Charities, Foundations & Non-Profits'},
    {value: 'computer_hardware', name: 'Computer Hardware'},
    {value: 'computer_software', name: 'Computer Software'},
    {value: 'construction_and_building_materials', name: 'Construction & Building Materials'},
    {value: 'consumer_products', name: 'Consumer Products'},
    {value: 'consumer_services', name: 'Consumer Services'},
    {value: 'crops_and_agriculture_protection', name: 'Crops & Agriculture Protection'},
    {value: 'chemicals', name: 'Chemicals'},
    {value: 'communications', name: 'Communications'},
    {value: 'corporate_services', name: 'Corporate Services'},
    {value: 'electronics', name: 'Electronics'},
    {value: 'energy_and_environmental', name: 'Energy & Environmental'},
    {value: 'entertainment', name: 'Entertainment'},
    {value: 'environmental', name: 'Environmental'},
    {value: 'financial_services', name: 'Financial Services'},
    {value: 'food_and_beverage', name: 'Food & Beverage'},
    {value: 'government', name: 'Government'},
    {value: 'holding_companies', name: 'Holding Companies'},
    {value: 'hospitality', name: 'Hospitality'},
    {value: 'hospitals_and_healthcare', name: 'Hospitals & Healthcare'},
    {value: 'industrial_manufacturing_and_services', name: 'Industrial Manufacturing & Services'},
    {value: 'insurance', name: 'Insurance'},
    {value: 'leisure_sports_and_recreation', name: 'Leisure, Sports & Recreation'},
    {value: 'media', name: 'Media'},
    {value: 'mining_and_metals', name: 'Mining & Metals'},
    {value: 'pharmaceuticals_and_biotech', name: 'Pharmaceuticals & Biotech'},
    {value: 'other', name: 'Other'},
    {value: 'real_estate_services', name: 'Real Estate Services'},
    {value: 'retail', name: 'Retail'},
    {value: 'schools_and_education', name: 'Schools & Education'},
    {value: 'shipping', name: 'Shipping'},
    {value: 'telecommunications', name: 'Telecommunications'},
    {value: 'utilities', name: 'Utilities'},
];
