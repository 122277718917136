import React from 'react';
import { ChatProps } from '../Types/types';

function Chat({ isTyping, messages }: ChatProps) {    

    // Add default message
    if (!messages.length) {
        messages.push({
            "sender": "bot", 
            "text": "How might I assist you?"
        });
    }

    return(
        <div className="chat-messages" data-testid="chat">
            {messages.map((message, index) => (
                <div key={index} className={`chat-message ${message.sender}`} dangerouslySetInnerHTML={{__html: message.text}} data-testid="chat-message"/>
            ))}
            {isTyping && <div className="chat-message bot" data-testid="typing-message">Typing...</div>}
        </div>
    );
}

export default Chat;