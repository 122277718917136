import React, { createContext, useReducer, ReactNode } from 'react';
import { initialState, reducer, State, Action } from './reducer';

interface ContextProps {
    state: State;
    dispatch: React.Dispatch<Action>;
}

interface ProviderProps {
    children: ReactNode;
}

export const InfoContext = createContext<ContextProps | undefined>(undefined);

export const InfoProvider: React.FC<ProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <InfoContext.Provider value={{ state, dispatch }}>
            { children }
        </InfoContext.Provider>
    );
};

export const MessageContext = createContext<ContextProps | undefined>(undefined);

export const MessageProvider: React.FC<ProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <MessageContext.Provider value={{ state, dispatch }}>
            { children }
        </MessageContext.Provider>
    );
};

export const ModalContext = createContext<ContextProps | undefined>(undefined);

export const ModalProvider: React.FC<ProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return(
        <ModalContext.Provider value={{ state, dispatch }}>
            { children }
        </ModalContext.Provider>
    );
};