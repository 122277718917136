import React from 'react';
import './App.css';
import ChatWindow from './components/ChatWindow/ChatWindow'
import Modal from './components/modals/Modal/Modal';
import { InfoProvider, MessageProvider, ModalProvider } from './components/state/Store';

function App() {
  return (
    <InfoProvider>
      <MessageProvider>
        <ModalProvider>
          <div className="App" data-testid="app">
              <div className='App-body'>
                <ChatWindow />

                <Modal modalType='info'/>
              </div>
          </div>
        </ModalProvider>
      </MessageProvider>
    </InfoProvider>
  );
}

export default App;
