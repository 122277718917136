import { Prompt } from '../Types/types';

/* Any reference to [companyName] or [profile] will get replaced with the profile var. */

export const attractPrompts: Prompt[] = [
    {
        displayPrompt: 'Create a prospecting email to get a meeting with this prospect',
        detailedPrompt: 'Create a prospecting email to get a meeting with this prospect, focused on [profile] challenges and the negative consequences of these, limited to 200 words, to best get the [profile] engaged.',
    },
    {
        displayPrompt: 'Create a prospecting call script to get a meeting with this prospect',
        detailedPrompt: 'Create a prospecting call script to get a meeting with this prospect, focused on the overall goals and objectives of [companyName] and specific [profile] challenges and the negative consequences of these.',
    },
    {
        displayPrompt: 'Who are the similar companies I should be targeting?',
        detailedPrompt: 'Who are the similar companies in the same industry and size to [companyName] that I should be targeting?',
    },
    {
        displayPrompt: 'Create a social post for similar prospects',
        detailedPrompt: 'Create a social post for similar prospects focused on [profile] challenges and the negative consequences of these.',
    },
];

export const engagePrompts: Prompt[] = [
    {
        displayPrompt: 'Help me to understand their business and market positioning',
        detailedPrompt: 'Help me to understand their business and market positioning',
    },
    {
        displayPrompt: 'Help me to understand their current financial performance and potential challenges',
        detailedPrompt: 'Using available sources such as VC / PE funding sources, press releases, and earnings call transcripts, help me to understand their financial performance metrics and potential financial challenge.',
    },
    {
        displayPrompt: `Help me to understand this prospect's competitors`,
        detailedPrompt: 'Help me to understand who this prospect competes with.',
    },
    {
        displayPrompt: 'Create a customized set of value discovery questions',
        detailedPrompt: 'Create specific value discovery questions I can ask the [profile] to uncover their Motivations for Change (Pains, Impacts), Alignment to Goals and Solution Needs, and Process for Decision Making.',
    },
    {
        displayPrompt: 'What are the typical strategic goals to solve with a solution like ours?',
        detailedPrompt: 'What are the typical strategic goals that [profile] would like to address with a solution like ours?',
    },
    {
        displayPrompt: 'Create a list of potential pain points and impacts',
        detailedPrompt: 'Create a list of potential pain points and impacts that we can help the [profile] to address.',
    },
    {
        displayPrompt: 'What are some challenge-oriented insights I can share to prompt the prospect to share?',
        detailedPrompt: 'What are some challenge-oriented insights and poor performance metrics that the [companyName] [profile] may be experiencing that I can share with them in order to prompt them in a conversation to share their own pains and impacts that we can help solve?',
    },
    {
        displayPrompt: 'Create a list of quantifiable business value benefits we can deliver',
        detailedPrompt: 'Create a list of quantifiable business value benefits we can deliver.',
    },
];

export const sellPrompts: Prompt[] = [
    {
        displayPrompt: 'Recommend a set of solution features to highlight',
        detailedPrompt: 'Recommend a set of solution offerings and the differentiating features that align with [profile] pains and priorities.',
    },
    // {
    //     displayPrompt: 'Create a list of potential business value benefits',
    //     detailedPrompt: 'What are the top three-to-five quantifiable benefits our solutions can deliver for a [profile]?',
    // },
    {
        displayPrompt: 'Provide a list of relevant success story quotes and links',
        detailedPrompt: ' Provide some customer success story summaries and specific quotes that would be most relevant to share.',
    },
    {
        displayPrompt: 'What barriers do prospects like this have with purchasing a solution like ours?',
        detailedPrompt: 'What barriers do prospects like this have with purchasing a solution like ours?',
    },
    {
        displayPrompt: 'What are the typical purchase decision making steps to go through?',
        detailedPrompt: 'What are the typical purchase decision making steps to go through when purchasing a solution like ours?',
    },
    {
        displayPrompt: 'Who are the typical stakeholders involved in the decision making process?',
        detailedPrompt: 'Who are the typical stakeholders involved in the decision making process?',
    },
    {
        displayPrompt: 'Who are the typical competitors we would face in an account like this?',
        detailedPrompt: 'Who are the typical competitors we would face in an account like this?',
    },
    {
        displayPrompt: 'What are our competitive advantages?',
        detailedPrompt: 'What are our competitive advantages we should highlight for this prospect?',
    },
];

export const renewExpandPrompts: Prompt[] = [
    {
        displayPrompt: 'What are the typical KPIs to use for proving realized value?',
        detailedPrompt: 'What are the typical KPIs to use for proving realized value of our solution for this prospect?',
    },
    {
        displayPrompt: 'What are the next use cases to recommend?',
        detailedPrompt: 'What are the next Genius Drive solutions and use cases to recommend to this prospect?',
    },
    {
        displayPrompt: 'Who are the other roles / functions to engage within the organization?',
        detailedPrompt: 'Who are the other executive and leadership roles to engage within the organization?',
    },
];