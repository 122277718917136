import React, { useContext } from 'react';
import InfoModal from '../../modals/InfoModal/InfoModal';
import { ModalProps } from '../../Types/types';
import { ModalContext } from '../../state/Store';
import './Modal.css';

function Modal({ modalType }: ModalProps) {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('ModalContext must be used with a ModalProvider');
    }

    const { state } = context;

    return(
        <>
            { state.isOpen && 
                <div className='modal'>
                    <div className='modal-body'>
                        { modalType && modalType === 'info' && <InfoModal />}
                    </div>
                </div>
            }
        </>
    );
}

export default Modal;