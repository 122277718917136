import { IndustryOption } from '../inputs/industryOptions';
import { ProfileOption } from '../inputs/profileOptions';

type ActionTypes = 'SEND_MESSAGE' | 'TOGGLE_MODAL' | 'UPDATE_INFO';

interface Info {
    companyName: string;
    industry: IndustryOption["value"];
    profile: ProfileOption["value"];
}

export interface State {
    info: Info;
    isOpen: boolean;
    message: string;
}

export const initialState: State = {
    info: {
        companyName: '',
        industry: '',
        profile: '',
    },
    isOpen: true,
    message: '',
};

export type Action = {
    info?: Info,
    type: ActionTypes,
    message?: string,
}

export const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'SEND_MESSAGE':
            return {
                ...state,
                message: action.message,
            };
        case 'TOGGLE_MODAL':
            return {
                ...state,
                isOpen: !state.isOpen,
            };
        case 'UPDATE_INFO':
            return {
                ...state,
                info: {
                    companyName: action.info?.companyName,
                    industry: action.info?.industry,
                    profile: action.info?.profile,
                },
            };   
        default:
            return state;
    }
};
