import { StageType } from '../Types/types';

interface FormatMessageParams {
    stage: StageType
    text: string;
    companyName?: string;
    industry?: string;
    profile?: string;
}

export function parseResult(text: string): string {
    let parsedText = text;
    parsedText = boldText(parsedText);
    parsedText = replaceTripleHashWithHeader(parsedText);
    parsedText = replaceQuadrupleHashWithHeader(parsedText);
    parsedText = addNewLines(parsedText);
    parsedText = removeCitations(parsedText);

    return parsedText;
};

function addNewLines(text: string): string {
    return text
        // Only add one <br> if double new-line chars happen after an h3 or h4
        .replace(/(<h3>[^<]*<\/h3>|<h4>[^<]*<\/h4>)\n\n/g, '$1<br>')
        // Turn any single new-line char into a <br>
        .replace(/\n/g, '<br>');
};

function boldText(text: string): string {
    return text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/__(.*?)__/g, '<strong>$1</strong>');
};

function replaceTripleHashWithHeader(text: string): string {
    return text.replace(/^### (.*$)/gim, `<h3>$1</h3>`);
};

function replaceQuadrupleHashWithHeader(text: string): string {
    return text.replace(/^#### (.*$)/gim, `<h4>$1</h4>`);
};

function removeCitations(text: string): string {
    return text.replace(/【[^【】]*】/g, '');
};

function formatStageName(stage: StageType): string {
    let formattedStageName =''
    switch (stage) {
        case 'attract':
            formattedStageName = 'Attract';
            break;
        case 'engage':
            formattedStageName = 'Engage';
            break;
        case 'renew':
            formattedStageName = 'Renew / Expand';
            break;
        case 'sell':
            formattedStageName = 'Sell';       
    }

    return formattedStageName;
};

export function formatMessage({ companyName, industry, profile, stage, text}: FormatMessageParams): string {
    let prependedText = '';

    if (companyName) {
        prependedText += `I am selling to ${companyName} `;
    }

    if (industry) {
        prependedText += `in the ${industry} industry `;
    }

    if (profile) {
        prependedText += `speaking to a ${profile} `;
    }

    const formattedStageName = formatStageName(stage);
    prependedText += `in the ${formattedStageName} stage of the sales process.`;

    return `${prependedText.charAt(0).toUpperCase() + prependedText.slice(1)} ${text}`;
};

export function formatPrompt(prompt: string, companyName?: string, profile?: string): string {
    let formattedPrompt = prompt;
    
    if (companyName) {
        formattedPrompt = formattedPrompt.replace(/\[companyName\]/g, companyName);
    }

    if (profile) {
        formattedPrompt = formattedPrompt.replace(/\[profile\]/g, profile);
    }

    return formattedPrompt;
};
