import React, { useState } from 'react';
import { TextInputProps } from '../Types/types';
import './inputs.css';

function TextInput({ label, onInputChanged, value }: TextInputProps) {
    const [textValue, setTextValue] = useState(value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setTextValue(value);

        // TODO: Add debounce?
        onInputChanged(value);
    };

    return (
        <div className='text-input'>
            <label htmlFor={ `text-input-${label.toLowerCase()}` }>{ label }</label>
            <input type='text' className='text-input-input' onChange={ handleChange } placeholder={ label } value={ textValue }/>
        </div>
    );
}

export default TextInput;