import React, { useContext, useEffect, useState } from 'react';
import Dropdown from '../../inputs/Dropdown';
import TextInput from '../../inputs/TextInput';
import { industryOptions } from '../../inputs/industryOptions';
import { profileOptions } from '../../inputs/profileOptions';
import { ModalContext } from '../../state/Store';
import './InfoModal.css';


function InfoModal() {
    useEffect(() => { validateInputs(); }, []);

    const [isValidated, setIsValidated] = useState(false);

    const NUM_REQUIRED = 2;

    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('ModalContext must be used with a ModalProvider');
    }

    // eslint-disable-next-line
    const { state, dispatch } = context;

    let infoObj = {
        companyName: state.info.companyName,
        industry: state.info.industry,
        profile: state.info.profile,
    };

    const handleCompanyChange = (value: string): void => {
        infoObj = { ...infoObj, companyName: value };

        dispatch({
            type: 'UPDATE_INFO',
            info: infoObj,
        });

        validateInputs();
    };

    const handleIndustryChange = (value: string): void => {
        const selectedIndustry = industryOptions.find(industry => value === industry.value);
        if (selectedIndustry) {
            infoObj = { ...infoObj, industry: selectedIndustry.name };

            dispatch({
                type: 'UPDATE_INFO',
                info: infoObj,
            });

            validateInputs();
        }
    };

    const handleProfileChange = (value: string): void => {
        const selectedProfile = profileOptions.find(profile => value === profile.value);
        if (selectedProfile) {
            infoObj = { ...infoObj, profile: selectedProfile.name };

            dispatch({
                type: 'UPDATE_INFO',
                info: infoObj,
            });

            validateInputs();
        }
    };

    function handleModalClick(): void {
        dispatch({
            type: 'TOGGLE_MODAL',
        })
    };

    function handleSubmit(): void {
        // const postInfo = async() => {
        //     // Make API call to save info
        //     const res = await fetch('/api/companyInfo', {
        //         method: 'POST',
        //         headers: { 'Content-Type': 'application/json' },
        //         body: JSON.stringify({ infoObj }),
        //     });

        //     if (!res.ok) {       
        //         // TODO: Send a bot message     
        //         throw new Error('Network response not ok.');
        //     }
      
        //     const result = await res.json();    
        // }

        // postInfo();

        if (isValidated) {
            handleModalClick();
        }

        // TODO: SHOW ERR
    };

    function validateInputs(): void {
        console.log('INFO OBJ', infoObj)
        let numValid = 0;

        if (infoObj.companyName !== '' || (infoObj.industry !== '' && infoObj.industry !== 'Select One')) {
            numValid++;
        }

        if (infoObj.profile !== '' && infoObj.profile !== 'Select One') {
            numValid++;
        }

        if (numValid >= NUM_REQUIRED) {
            setIsValidated(true);
            return;
        }

        setIsValidated(false);
    };

    const selectedIndustry = industryOptions.find(industry => state.info.industry === industry.name);
    const selectedProfile = profileOptions.find(profile => state.info.profile === profile.name);

    return(
        <>
            <div className='info-modal-body'>
                <div className='info-modal-body-section-header header mb-5'>Customer Profile</div>

                <div className='italic mb-20'>Complete this form to get a more-detailed chat response.</div>

                <div className='info-modal-body-section-header header mb-20'>Company Information*</div>

                <TextInput label='Company Name' onInputChanged={ handleCompanyChange } value={ state.info.companyName }/>
                
                <Dropdown label='Industry' onDropdownChanged={ handleIndustryChange } options={ industryOptions } value={ selectedIndustry?.value }/>

                <div className='info-modal-body-section-header header mb-20'>Prospect Profile</div>

                <Dropdown label='Role*' onDropdownChanged={ handleProfileChange } options={ profileOptions } value={ selectedProfile?.value }/>
            </div>

            <div className='modal-footer'>
                <div className='modal-footer-required'>Fields marked with * are required.</div>

                <div className='modal-footer-buttons'>
                    <button className='info-modal-button mr-10' disabled={ !isValidated } onClick={ handleModalClick }>Cancel</button>
                    <button className='info-modal-button info-modal-button-submit' disabled={ !isValidated } onClick={ handleSubmit }>Submit</button>
                </div>
            </div>
        </>    
    );
}

export default InfoModal;