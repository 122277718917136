export interface ProfileOption {
    value: string;
    name: string;
} 

export const profileOptions: ProfileOption[] = [
    {value: '', name: 'Select One'},
    {value: 'customer-success-leader', name: 'Customer Success Leader'},
    {value: 'marketing-leader', name: 'Marketing Leader'},
    {value: 'sales-leader', name: 'Sales Leader'},
    {value: 'value-program-leader', name: 'Value Program Leader'},
];