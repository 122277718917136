import React, { useState } from 'react';
import { DropdownProps } from '../Types/types';

function Dropdown({ label, onDropdownChanged, options, value }: DropdownProps) {
    const [selectedOption, setSelectedOption] = useState(value);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setSelectedOption(value);
        onDropdownChanged(value);
    };

    return (
        <div className='dropdown'>
            <label htmlFor={ `dropdown-${label.toLowerCase()}` }>{ label }</label>
            <select className='dropdown-input' id={ `dropdown-${label.toLowerCase()}` } value={ selectedOption } onChange={ handleChange }>
                {options.map((option, index) => {
                    return (<option key={ index } value={ option.value }>{ option.name }</option> )
                })}
            </select>
        </div>
    );
}

export default Dropdown;