import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo} from '@fortawesome/free-solid-svg-icons';
import { ModalContext } from '../state/Store';
import './Header.css'

function Header() {
    
    const [headerText, setHeaderText] = useState<string>('');

    const context = useContext(ModalContext);

    if (!context) {
        throw new Error('ModalContext must be used with a ModalProvider');
    }

    const { state, dispatch } = context;

    useEffect(() => { createHeaderText() }, [state.info]);

    function createHeaderText(): void {
        let varCount = 0;
        let headerText = '';

        if (state.info.companyName) {
            headerText += `${state.info.companyName}, `
            varCount++;
        }

        if (state.info.industry && state.info.industry !== 'Select One') {
            headerText += `${state.info.industry}, `
            varCount++;
        }

        if (state.info.profile && state.info.profile !== 'Select One') {
            headerText += `${state.info.profile} `
            varCount++;
        }

        // Remove trailing comma if only 1 or 2 vars completed
        if (varCount < 3) {
            headerText = headerText.replace(/,\s*$/, "");
        }

        setHeaderText(headerText);
    };

    function toggleInfoModal(): void {
        dispatch({ type: 'TOGGLE_MODAL' });
    };

    return(
        <div className='chat-header' data-testid="chat-header">
            <FontAwesomeIcon className='chat-header-icon' icon={ faCircleInfo } onClick={ toggleInfoModal }/>
            <div className='chat-header-company' data-testid="company-name">{ headerText }</div>
        </div>
    );
}

export default Header;