import React, { useContext, useEffect, useState } from 'react';
import { MessageContext, ModalContext } from '../state/Store'
import Chat from '../Chat/Chat'
import Header from '../Header/Header';
import Input from '../Input/Input';
import InfoTabs from '../Tabs/InfoTabs';
import { Message } from '../Types/types';
import { parseResult } from '../utils/messageUtils';
import './ChatWindow.css';

function ChatWindow() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isTyping, setIsTyping] = useState(false);

  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('MessageContext must be used with a MessageProvider');
  }

  const { state, dispatch } = context;

  const modalContext = useContext(ModalContext);
  if (!modalContext) {
    throw new Error('ModalContext must be used with a ModalProvider');
  }

  useEffect(() => {
    const getMessage = async (message: string) => {
      try {
        const newMessage: Message = { text: state.message, sender: 'user' };
        setMessages(messages => [...messages, newMessage]);
        setIsTyping(true);

        const res = await fetch('/api/assistant', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ message }),
        });

        if (!res.ok) {
          const responseMessage: Message = { text: 'Sorry, the network response failed.', sender: 'bot' };
          setMessages(prevMessages => [...prevMessages, responseMessage]);
          throw new Error('Network response not ok.');
        }

        const result = await res.json();
        const parsedResult = parseResult(result.reply);
        const responseMessage: Message = { text: parsedResult, sender: 'bot' };
        setMessages(prevMessages => [...prevMessages, responseMessage]);
      } catch (error) {
        console.warn(error)
      }
      finally {
        setIsTyping(false);
      }
    }

    if (state.message !== '') {
		  getMessage(state.message);
    }
  }, [state.message])

  const handleSendMessage = (input: string): void => {
    if (input.trim() !== '') {
      dispatch({
        type: 'SEND_MESSAGE',
        message: input,
      })
    }
  };

  return (
    <div className={`chat-window ${modalContext.state.isOpen ? 'blur-bg': ''}`.trim() } data-testid="chat-window">
      <Header />

      <Chat isTyping={ isTyping } messages={ messages } />

      <InfoTabs />

      <Input onInputEntered={ handleSendMessage } />
    </div>
  );
}

export default ChatWindow;
