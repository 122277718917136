import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Tag from '../Tag/Tag';
import { attractPrompts, engagePrompts, renewExpandPrompts, sellPrompts } from '../utils/stagePrompts';
import 'react-tabs/style/react-tabs.css';
import './InfoTabs.css';

function InfoTabs() {

	const [tabExpanded, setTabExpanded] = useState(true);

	function handleIconClick() {
		setTabExpanded(!tabExpanded);
	}

	return(
		<Tabs className={ `tab-container ${tabExpanded ? 'tab-container-expanded' : ''}`.trim() }>
			<div className='tab-container-icon-row'>
				<FontAwesomeIcon className={ `tab-container-icon-row-icon ${ tabExpanded ? 'rotate180' : '' }`.trim() } icon={ faChevronUp } onClick={ handleIconClick }/>
			</div>

			<TabList>
				<Tab>Attract</Tab>
				<Tab>Engage</Tab>
				<Tab>Sell</Tab>
				<Tab>Renew / Expand</Tab>
			</TabList>
			
			<TabPanel>
				{ attractPrompts.map((prompt, index) => (
					<Tag key={ index } stage='attract' tagType='prompt' prompt={ prompt } /> 
				))}
			</TabPanel>
			<TabPanel>
				{ engagePrompts.map((prompt, index) => (
					<Tag key={ index } stage='engage' tagType='prompt' prompt={ prompt } /> 
				))}
			</TabPanel>
			<TabPanel>
				{ sellPrompts.map((prompt, index) => (
					<Tag key={ index } stage='sell' tagType='prompt' prompt={ prompt } /> 
				))}
			</TabPanel>
			<TabPanel>
				{ renewExpandPrompts.map((prompt, index) => (
					<Tag key={ index } stage='renew' tagType='prompt' prompt={ prompt } /> 
				))}
			</TabPanel>
		</Tabs>
	)
}

export default InfoTabs;