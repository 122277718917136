import React, { useContext } from 'react';
import { MessageContext, ModalContext } from '../state/Store';
import { TagProps } from '../Types/types';
import { formatMessage, formatPrompt } from '../utils/messageUtils'
import './Tag.css';

function Tag({ tagType, prompt, stage }: TagProps) {

    const messageContext = useContext(MessageContext);
    if (!messageContext) {
        throw new Error('MessageContext must be used with with a MessageProvider');
    }
    const modalContext = useContext(ModalContext);
    if (!modalContext) {
        throw new Error('ModalContext must be used with with a ModalProvider');
    }

    // eslint-disable-next-line
    const { state, dispatch } = messageContext;

    function handleSendMessage(): void {
        /* 
            Use the displayPrompt, unless companyName or profile were supplied by the user.
        */
        let promptToUse = prompt.displayPrompt;

        let companyName = modalContext?.state.info.companyName;
        let profile = modalContext?.state.info.profile;

        if (companyName || profile) {
            /*
                It's possible the user filled in one value, but not all.
                If so, we want to use the detailedPrompt, but fill in the missing info.
            */

            companyName = modalContext?.state.info.companyName || 'Typical Prospect Company';
            profile = modalContext?.state.info.profile || 'Typical Buyer Role';

            promptToUse = formatPrompt(prompt.detailedPrompt, companyName, profile);
        }

        const formattedMessage = formatMessage({
            stage,
            text: promptToUse,
            companyName,
            industry: modalContext?.state.info.industry,
            profile,
        });

        dispatch({
            type: 'SEND_MESSAGE',
            message: formattedMessage,
        });
    }

    return(
        <div className={`tag ${tagType === 'info' ? 'tag-info' : 'tag-prompt'}`} onClick={ handleSendMessage }>
            { prompt.displayPrompt }
        </div>
    );
}

export default Tag;